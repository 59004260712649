@charset "UTF-8";
/*
---
name: Mixin
tag:
  - base
category:
  - foundation
  - foundation/mixin
---


mixinを定義しています

*/
body {
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .inner {
    box-sizing: border-box;
    padding: 0 15px;
  }
}

header {
  background: none;
  position: absolute;
  width: 100%;
  z-index: 10;
}

@media only screen and (min-width: 769px) {
  header {
    top: 40px;
  }
}

header .logo {
  display: none;
}

@media only screen and (min-width: 769px) {
  #nav_inner ul {
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  #nav_inner li a,
  #nav_inner li .pull {
    color: #fff;
  }
}

@media only screen and (min-width: 769px) {
  #nav_inner li .pull {
    background: url("/assets/img/index/icon_nav_arrow_w.png") no-repeat right center;
    background-size: 6px auto;
  }
}

@media only screen and (min-width: 769px) {
  header.fixed #nav_inner li .pull {
    background-image: url("/assets/img/index/icon_nav_arrow_g.png");
  }
}

@media only screen and (max-width: 768px) {
  #btn_menu div {
    background-color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  #btn_menu.on div {
    background-color: #747474;
  }
}

header.fixed {
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0px;
  z-index: 1000;
}

header.fixed .inner {
  max-width: 100%;
}

header.fixed .logo {
  left: 15px;
  top: 12px;
  float: none;
  display: block;
}

@media only screen and (max-width: 768px) {
  header.fixed .logo {
    position: relative;
    top: 0;
  }
}

header.fixed #nav_inner li a,
header.fixed #nav_inner li .pull {
  color: #000;
}

header.fixed #btn_nenu div {
  background-color: #747474;
}

#contents {
  padding: 132px 0 80px;
  background-color: #eaeaea;
}

@media screen and (min-width: 1280px) {
  #contents {
    padding: 168px 0 80px;
  }
}

#contents section h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 40px 0;
}

#contents section h2 span {
  display: block;
  color: #4db332;
  font-size: 18px;
}

.btns {
  text-align: center;
}

.btn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  min-width: 220px;
  padding: 10px 20px;
  background-color: #4db332;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s cubic-bezier(0, 0.5, 0.35, 1.5);
}

.btn:hover {
  transform: scale(1.07);
}

.btn:after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
  width: 21px;
  height: 21px;
  background: url("/assets/img/index/icon_btn_arrow.png") no-repeat center center;
  background-size: contain;
}

#fixed {
  position: relative;
  overflow: hidden;
}

#circle_mask {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2000;
}

#top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 url("/assets/img/index/loader.gif") no-repeat center 65%;
}

#top .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/assets/img/index/top_cover.png");
  background-color: rgba(0, 0, 0, 0.1);
}

#bg_video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bg_video canvas {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#top_video {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#circle {
  position: absolute;
  height: 100%;
}

#circle div {
  position: absolute;
  border-radius: 100%;
  backface-visibility: hidden;
}

#circle div:nth-child(1) {
  height: 52%;
  top: -10%;
  transform: translateX(-45%);
  background-color: #4db332;
  opacity: 0.7;
}

#circle div:nth-child(2) {
  height: 60%;
  top: 30%;
  transform: translateX(-40%);
  background-color: #4db332;
  opacity: 0.5;
}

#circle div:nth-child(3) {
  height: 40%;
  top: 70%;
  transform: translateX(-45%);
  background-color: #fff;
  opacity: 0.2;
}

#circle div:nth-child(4) {
  height: 56%;
  top: 20%;
  transform: translateX(-42%);
  background-color: #fff;
  opacity: 0.2;
}

#top .inner {
  position: relative;
  height: 100%;
}

#top h1 {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 520px) {
  #top h1 {
    font-size: 16px;
  }
}

#top h1 * {
  display: block;
  margin: auto;
}

#top h1 span {
  margin-top: 20px;
}

@media only screen and (max-width: 520px) {
  #top h1 img {
    max-width: 75%;
  }
}

#top .news {
  box-sizing: border-box;
  position: absolute;
  width: 93%;
  height: 52px;
  bottom: 100px;
  left: 0;
  right: 0;
  padding: 10px 20px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
}

@media only screen and (max-width: 768px) {
  #top .news {
    bottom: 70px;
    height: 85px;
  }
}

#top .news ol {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#top .news li {
  position: absolute;
  display: none;
}

#top .news time {
  display: block;
  float: left;
  width: 90px;
  padding: 5px 0 0 0;
  font-size: 14px;
}

#top .news h4 {
  box-sizing: border-box;
  float: left;
  width: 130px;
  padding: 5px;
  background-color: #555;
  border-radius: 6px;
  text-align: center;
  color: #fff;
}

#top .news h3 {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 0px;
  height: 1.4em;
  padding: 5px 0 0 10px;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  #top .news h3 {
    float: none;
    clear: both;
    padding: 10px 0 0 0;
    width: 100%;
  }
}

#top .fixedNews {
  box-sizing: border-box;
  position: absolute;
  width: 93%;
  height: 52px;
  bottom: 168px;
  left: 0;
  right: 0;
  padding: 10px 20px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

@media only screen and (max-width: 768px) {
  #top .fixedNews {
    bottom: 171px;
    height: 85px;
  }
}

#top .fixedNews ol {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #fff;
}

#top .fixedNews li {
  position: absolute;
  color: #fff;
}

#top .fixedNews time {
  display: block;
  float: left;
  width: 90px;
  padding: 5px 0 0 0;
  font-size: 14px;
}

#top .fixedNews h4 {
  box-sizing: border-box;
  float: left;
  width: 130px;
  padding: 5px;
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  color: #555;
}

#top .fixedNews h3 {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 0px;
  height: 1.4em;
  padding: 5px 0 0 10px;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  #top .fixedNews h3 {
    float: none;
    clear: both;
    padding: 10px 0 0 0;
    width: 100%;
  }
}

#top .fixedNews a {
  color: #fff;
}

#top_scroll {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  width: 220px;
  padding: 10px 20px;
  background-color: #4db332;
  border-radius: 6px;
  transition: transform 0.3s cubic-bezier(0, 0.5, 0.35, 1.5);
}

#top_scroll:hover {
  transform: scale(1.07);
}

@media only screen and (max-width: 768px) {
  #top_scroll {
    bottom: 15px;
  }
}

#message {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee url("/assets/img/home/message_bg.jpg") no-repeat left top;
  background-size: cover;
}

#message .inner {
  position: relative;
  height: 100%;
}

#message .txt {
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  width: 60%;
  max-width: 550px;
}

@media only screen and (max-width: 1000px) {
  #message .txt {
    right: 15px;
  }
}

@media only screen and (max-width: 550px) {
  #message .txt {
    width: 80%;
    left: 0;
    right: 0;
    top: 55%;
    margin: auto;
  }
}

#message .txt h2 {
  color: #4db332;
  font-size: 18px;
}

#message .txt h3 {
  display: block;
  margin-top: 0.5em;
  font-size: 38px;
  font-weight: bold;
  text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 1px #fff;
}

@media only screen and (max-width: 550px) {
  #message .txt h3 {
    font-size: 32px;
  }
}

#message .txt .btn {
  margin-top: 4em !important;
}

@media only screen and (max-width: 550px) {
  #message .txt .btn {
    display: block;
    width: 220px;
    margin: auto;
    margin-top: 1.5em;
  }
}

#business ul {
  margin: 0 -7px;
}

#business li {
  box-sizing: border-box;
  float: left;
  width: 25%;
  padding: 0 7px;
  margin-bottom: 3em;
}

@media only screen and (max-width: 768px) {
  #business li {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) {
  #business li:nth-child(4+1) {
    clear: both;
  }
}

@media only screen and (max-width: 768px) {
  #business li:nth-child(2n+1) {
    clear: both;
  }
}

#business li a {
  text-decoration: none;
}

#business .img {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
}

#business p {
  margin-top: 1.5em;
}

#contents .inner > div.cf {
  margin: 80px -7px 0  -7px;
}

@media only screen and (max-width: 768px) {
  #contents .inner > div.cf {
    margin: 60px 0 0 0;
  }
}

#news {
  box-sizing: border-box;
  float: left;
  width: 75%;
  padding: 0 7px;
}

@media only screen and (max-width: 768px) {
  #news {
    float: none;
    width: 100%;
    padding: 0;
  }
}

#news .list {
  height: 240px;
  background-color: #fff;
  border-radius: 6px;
  overflow: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
  #news .list {
    height: 300px;
  }
}

#news .list ol {
  padding: 20px 0;
}

#news .list li {
  margin: 0;
  padding: 8px 30px;
}

#news .list li * {
  vertical-align: top;
}

#news .list li time {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 90px;
  padding: 4px 0 0 0;
  font-size: 14px;
}

#news .list li h4 {
  box-sizing: border-box;
  float: left;
  width: 130px;
  padding: 5px;
  background-color: #555;
  border-radius: 6px;
  text-align: center;
  color: #fff;
}

#news .list li h3 {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 440px;
  padding: 4px 0 0 15px;
  font-size: 16px;
  font-weight: normal;
}

@media only screen and (max-width: 1000px) {
  #news .list li h3 {
    float: none;
    clear: both;
    width: 100%;
    padding: 15px 0 0 0;
  }
}

#recruit {
  box-sizing: border-box;
  float: right;
  width: 25%;
  padding: 0 7px;
}

@media only screen and (max-width: 768px) {
  #recruit {
    float: none;
    width: 100%;
    padding: 60px 0 0 0;
  }
}

#recruit a {
  text-decoration: none;
}

#recruit .img {
  background-color: #fff;
  border-radius: 6px;
  height: 240px;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  #recruit .img {
    text-align: center;
  }
}

#pagetop {
  box-sizing: border-box;
  display: block;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
  margin-top: 4em;
  text-align: center;
  cursor: pointer;
  width: 220px;
  padding: 10px 20px;
  background-color: #4db332;
  border-radius: 6px;
  transition: transform 0.3s cubic-bezier(0, 0.5, 0.35, 1.5);
}

#pagetop:hover {
  transform: scale(1.07);
}

#pagetop img {
  transform: rotate(180deg);
}
