// ---------------------------------------------------
//
// Foundation
//
// ---------------------------------------------------

@import 'foundation/variables';
@import 'foundation/mixin';

body {
  position: relative;
}

@media only screen and (max-width:1000px) {
	.inner {
		box-sizing:border-box;
		padding:0 15px;
	}
}


// ---------------------------------------
//  #header
// ---------------------------------------

header {
	background: none;
	position: absolute;
	width: 100%;
  z-index: 10;
  @media only screen and (min-width:769px) {
    top: 40px;
  }

  & .logo {
    display: none;
  }
}

#nav_inner ul {
  @media only screen and (min-width:769px) {
    text-align: center;
  }
}

#nav_inner li a,
#nav_inner li .pull {
  @media only screen and (min-width:769px) {
    color:#fff;
  }
}

#nav_inner li .pull {
  @media only screen and (min-width:769px) {
    background:url($pathToImg + "index/icon_nav_arrow_w.png") no-repeat right center;
    background-size:6px auto;
  }
}

header.fixed #nav_inner li .pull {
  @media only screen and (min-width:769px) {
    background-image:url($pathToImg + "index/icon_nav_arrow_g.png");
  }
}

#btn_menu div {
  @media only screen and (max-width:768px) {
    background-color:#fff;
  }
}

#btn_menu.on div {
  @media only screen and (max-width:768px) {
    background-color:#747474;
  }
}

header.fixed {
	position:fixed;
	width:100%;
	background-color:#fff;
	top:0px;
  z-index:1000;

  & .inner {
    max-width:100%;
  }

  & .logo {
    left:15px;
    top:12px;
    float:none;
    display:block;
    @media only screen and (max-width:768px) {
      position:relative;
      top:0;
    }
  }

  & #nav_inner li a,
  & #nav_inner li .pull {
    color:#000;
  }

  & #btn_nenu div {
    background-color:#747474;
  }
}


// ---------------------------------------
//  #contents
// ---------------------------------------

#contents {
	padding:132px 0 80px;
  background-color:#eaeaea;
  @include widescreen {
    padding: 168px 0 80px;
  }

  & section h2 {
    text-align:center;
    font-size:24px;
    font-weight:bold;
    margin:0 0 40px 0;

    & span {
      display:block;
      color:#4db332;
      font-size:18px;
    }
  }
}

.btns {
	text-align:center;
}

.btn {
	position:relative;
	box-sizing:border-box;
	display:inline-block;
	min-width:220px;
	padding:10px 20px;
	background-color:#4db332;
	border-radius:6px;
	text-align:center;
	text-decoration:none;
	color:#fff;
	font-size:18px;
  transition:all .3s cubic-bezier(0,.5,.35,1.5);

  &:hover {
    transform:scale(1.07);
  }

  &:after {
    position:absolute;
    content:'';
    display:block;
    top:0;
    bottom:0;
    right:12px;
    margin:auto;
    width:21px;
    height:21px;
    background:url($pathToImg + "index/icon_btn_arrow.png") no-repeat center center;
    background-size:contain;
  }
}


// ---------------------------------------
//  #fixed
// ---------------------------------------

#fixed {
	position:relative;
	overflow:hidden;
}

#circle_mask {
	display:none;
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	// margin:auto;
	margin: 0 auto;
	z-index:2000;
}

// #circle_mask circle {}

// ---------------------------------------
//  #top
// ---------------------------------------

#top {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
  background:#000 url($pathToImg + "index/loader.gif") no-repeat center 65%;

  & .cover {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:url($pathToImg + "index/top_cover.png");
    background-color: rgba(0,0,0,.1);
  }
}

#bg_video {
	position:absolute;
	top:50%;
	left:50%;
  transform:translate(-50%, -50%);

  & canvas {
    display:none;
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
}

#top_video {
	display:none;
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
}

#circle {
	position:absolute;
  height:100%;

  & div {
    position:absolute;
    border-radius:100%;
    backface-visibility:hidden;

    &:nth-child(1) {
      height:52%;
      top:-10%;
      transform:translateX(-45%);
      background-color:#4db332;
      opacity:0.7;
    }

    &:nth-child(2) {
      height:60%;
      top:30%;
      transform:translateX(-40%);
      background-color:#4db332;
      opacity:0.5;
    }

    &:nth-child(3) {
      height:40%;
      top:70%;
      transform:translateX(-45%);
      background-color:#fff;
      opacity:0.2;
    }

    &:nth-child(4) {
      height:56%;
      top:20%;
      transform:translateX(-42%);
      background-color:#fff;
      opacity:0.2;
    }
  }
}

#top {

  & .inner {
    position:relative;
    height:100%;
  }

  & h1 {
    text-align:center;
    position:relative;
    top:50%;
    transform:translateY(-50%);
    color:#fff;
    font-size:20px;
    font-weight:bold;
    @media only screen and (max-width:520px) {
      font-size:16px;
    }

    & * {
      display:block;
      margin:auto;
    }

    & span {
      margin-top:20px;
    }

    & img {
      @media only screen and (max-width:520px) {
        max-width:75%;
      }
    }
  }
  & .news {
    box-sizing:border-box;
    position:absolute;
    width:93%;
    height:52px;
    bottom:100px;
    left:0;
    right:0;
    padding:10px 20px;
    margin:auto;
    background-color:rgba(255,255,255,0.7);
    border-radius:6px;
    @media only screen and (max-width:768px) {
      bottom:70px;
      height:85px;
    }

    & ol {
      position:relative;
      overflow:hidden;
      width:100%;
      height:100%;
    }

    & li {
      position:absolute;
      display:none;
    }

    & time {
      display:block;
      float:left;
      width:90px;
      padding:5px 0 0 0;
      font-size:14px;
    }

    & h4 {
      box-sizing:border-box;
      float:left;
      width:130px;
      padding:5px;
      background-color:#555;
      border-radius:6px;
      text-align:center;
      color:#fff;
    }

    & h3 {
      box-sizing:border-box;
      display:block;
      float:left;
      width:0px;
      height:1.4em;
      padding:5px 0 0 10px;
      font-size:16px;
      font-weight:normal;
      white-space:nowrap;
      @media only screen and (max-width:768px) {
        float:none;
        clear:both;
        padding:10px 0 0 0;
        width:100%;
      }
    }
  }

  & .fixedNews {
    box-sizing:border-box;
    position:absolute;
    width:93%;
    height:52px;
    bottom:168px;
    left:0;
    right:0;
    padding:10px 20px;
    margin:auto;
    background-color:rgba(0,0,0,0.5);
    border-radius:6px;
    @media only screen and (max-width:768px) {
      bottom:171px;
      height:85px;
    }

    & ol {
      position:relative;
      overflow:hidden;
      width:100%;
      height:100%;
      color: #fff;
    }

    & li {
      position:absolute;
      color: #fff;
    }

    & time {
      display:block;
      float:left;
      width:90px;
      padding:5px 0 0 0;
      font-size:14px;
    }

    & h4 {
      box-sizing:border-box;
      float:left;
      width:130px;
      padding:5px;
      background-color:#fff;
      border-radius:6px;
      text-align:center;
      color:#555;
    }

    & h3 {
      box-sizing:border-box;
      display:block;
      float:left;
      width:0px;
      height:1.4em;
      padding:5px 0 0 10px;
      font-size:16px;
      font-weight:normal;
      white-space:nowrap;
      color: #fff;
      @media only screen and (max-width:768px) {
        float:none;
        clear:both;
        padding:10px 0 0 0;
        width:100%;
      }
    }

    & a {
      color: #fff;
    }
  }
}

#top_scroll {
	box-sizing:border-box;
	position:absolute;
	left:0;
	right:0;
	bottom:30px;
	margin:auto;
	text-align:center;
	cursor:pointer;

	width:220px;
	padding:10px 20px;
	background-color:#4db332;
	border-radius:6px;
  transition:transform .3s cubic-bezier(0,.5,.35,1.5);

  &:hover {
    transform:scale(1.07);
  }

  @media only screen and (max-width:768px) {
    bottom:15px;
  }
}


// ---------------------------------------
//  #top
// ---------------------------------------

#message {
	display:none;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:#eee url($pathToImg + "home/message_bg.jpg") no-repeat left top;
  background-size:cover;

  & .inner {
    position:relative;
    height:100%;
  }

  & .txt {
    position:absolute;
    right:0;
    top:40%;
    transform:translateY(-50%);
    width:60%;
    max-width:550px;

    @media only screen and (max-width:1000px) {
      right:15px;
    }

    @media only screen and (max-width:550px) {
      width:80%;
      left:0;
      right:0;
      top:55%;
      margin:auto;
    }

    & h2 {
      color:#4db332;
      font-size:18px;
    }

    & h3 {
      display:block;
      margin-top:0.5em;
      font-size:38px;
      font-weight:bold;
      text-shadow:0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 1px #fff;

      @media only screen and (max-width:550px) {
        font-size:32px;
      }
    }

    & .btn {
      margin-top:4em !important;

      @media only screen and (max-width:550px) {
        display:block;
        width:220px;
        margin:auto;
        margin-top:1.5em;
      }
    }
  }
}


// ---------------------------------------
//  #business
// ---------------------------------------

#business {

  & ul {
    margin:0 -7px;
  }

  & li {
    box-sizing:border-box;
    float:left;
    width:25%;
    padding:0 7px;
    margin-bottom:3em;

    @media only screen and (max-width:768px) {
      width:50%;
    }

    &:nth-child(4+1) {
      @media only screen and (min-width:769px) {
        clear:both;
      }
    }

    &:nth-child(2n+1) {
      @media only screen and (max-width:768px) {
        clear:both;
      }
    }

    & a {
      text-decoration:none;
    }
  }
  & .img {
    background-color:#fff;
    border-radius:6px;
    overflow:hidden;
    text-align:center;
  }
  & p {
	  margin-top:1.5em;
  }
}

#contents .inner > div.cf {
  margin:80px -7px 0  -7px;
  @media only screen and (max-width:768px) {
    margin:60px 0 0 0;
  }
}


// ---------------------------------------
//  #news
// ---------------------------------------

#news {
	box-sizing:border-box;
	float:left;
	width:75%;
  padding:0 7px;

  @media only screen and (max-width:768px) {
    float:none;
		width:100%;
		padding:0;
  }

  & .list {
    height:240px;
    background-color:#fff;
    border-radius:6px;
    overflow:auto;
    overflow-x:hidden;

    @media only screen and (max-width:768px) {
      height:300px;
    }

    & ol {
      padding:20px 0;
    }

    & li {
      margin:0;
      padding:8px 30px;

      & * {
        vertical-align:top;
      }

      & time {
        box-sizing:border-box;
        display:block;
        float:left;
        width:90px;
        padding:4px 0 0 0;
        font-size:14px;
      }

      & h4 {
        box-sizing:border-box;
        float:left;
        width:130px;
        padding:5px;
        background-color:#555;
        border-radius:6px;
        text-align:center;
        color:#fff;
      }

      & h3 {
        box-sizing:border-box;
        display:block;
        float:left;
        width:440px;
        padding:4px 0 0 15px;
        font-size:16px;
        font-weight:normal;

        @media only screen and (max-width:1000px) {
          float:none;
          clear:both;
          width:100%;
          padding:15px 0 0 0;
        }
      }
    }
  }
}

// ---------------------------------------
//  #recruit
// ---------------------------------------

#recruit {
	box-sizing:border-box;
	float:right;
	width:25%;
  padding:0 7px;

  @media only screen and (max-width:768px) {
    float:none;
		width:100%;
		padding:60px 0 0 0;
  }

  & a {
    text-decoration:none;
  }

  & .img {
    background-color:#fff;
    border-radius:6px;
    height:240px;
    overflow:hidden;

    @media only screen and (max-width:768px) {
      text-align:center;
    }
  }
}

#pagetop {
	box-sizing:border-box;
	display:block;
	left:0;
	right:0;
	bottom:30px;
	margin:auto;
	margin-top:4em;
	text-align:center;
	cursor:pointer;

	width:220px;
	padding:10px 20px;
	background-color:#4db332;
	border-radius:6px;
  transition:transform .3s cubic-bezier(0,.5,.35,1.5);

  &:hover {
    transform:scale(1.07);
  }

  & img {
    transform:rotate(180deg);
  }
}
